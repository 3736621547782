import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const NewsContext = createContext();
export const useNews = () => {
    return useContext(NewsContext);
};

export default function NewsContextProvider({ children }) {

    const [isNews, setIsNews] = useState()
    const [isPostById, setIsPostById] = useState()
    const [isNewsCategory, setIsNewsCategory] = useState()
    const [isNewsCategoryById, setIsNewsCategoryById] = useState()

    async function getNews(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/news`, { headers });
            setIsNews(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPostById(id) {
        setIsPostById()
        try {
            const res = await axios.get(`${newBaseUrl}/api/admin/post/${id}`);
            setIsPostById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deletePost(id) {
        try {
            const res = await axios.delete(`${newBaseUrl}/api/delete/post/${id}`);
            // setIsNews(res?.data);
            // console.log(res?.data?.data);
            getNews()
        } catch (err) {
            console.error(err);
        }
    }

    async function getNewsCategory(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/news-category`, { headers });
            setIsNewsCategory(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getNewsCategoryById(id) {
        setIsNewsCategoryById()
        try {
            const res = await axios.get(`${newBaseUrl}/api/admin/news-category/${id}`);
            setIsNewsCategoryById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteNewsCategory(id) {
        try {
            const res = await axios.delete(`${newBaseUrl}/api/delete/news-category/${id}`);
            // setIsNews(res?.data);
            // console.log(res?.data?.data);
            getNewsCategory()
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <NewsContext.Provider value={{
            getNewsCategory, getNews, deleteNewsCategory, getNewsCategoryById, getPostById, deletePost,
            isNewsCategoryById, isNewsCategory, isNews, isPostById
        }}>
            {children}
        </NewsContext.Provider>
    );
}
