import React from "react";
import Transl from "../Components/Transl/Transl";
import Btn from "../Components/Banner/Btn";
import BannerTable from "../Components/Banner/BannerTable";

function Banner() {
  return (
    <div>
      <div className="flex justify-between items-center py-7 px-10">
        <Transl />
        <Btn />
      </div>
      <div className="px-10">
        <BannerTable />
      </div>
    </div>
  );
}

export default Banner;
