import React, { useState } from 'react'
import { useAbout } from '../../../Context/AboutContext';

function Btn() {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { getAbout } = useAbout();

  return (
    <div className='w-full flex flex-row items-center justify-between'>
      <select
        name="language"
        id="language"
        value={selectedLanguage}
        onChange={(e) => {
          setSelectedLanguage(e.target.value);
          getAbout(e.target.value);
        }}
        className='w-[150px] border rounded-lg px-2 py-1 pr-2'
      >
        <option value="en">English</option>
        <option value="ar">Arabic</option>
      </select>
    </div>

  )
}

export default Btn