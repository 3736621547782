import React from 'react'

function Transl() {
  return (
    <select name="" id="" className='border rounded-lg px-2 py-1 pr-2'>
        <option>English</option>
        <option>Arabic</option>
    </select>
  )
}

export default Transl