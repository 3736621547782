import React from "react";
import { Link } from "react-router-dom";

function Btn() {
  return (
    <Link to={"/NewCategoryOfProducts"}>
      <div className="text-[20px] font-[200] text-[#fff] px-5 rounded-3xl py-2 bg-[#0966AE] font-[Cairo]">
        New Category
      </div>
    </Link>
  );
}

export default Btn;
