import React from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Layout from './Layout/Layout';
import Banner from './Pages/Banner';
import AllServices from './Pages/AllServices';
import About from './Pages/About';
import CategoryOfServices from './Pages/CategoryOfServices';
import NewService from './Pages/NewService';
import NewCategory from './Pages/NewCategory';
import AllProject from './Pages/AllProject';
import NewProject from './Pages/NewProject';
import CategoryOfProjects from './Pages/CategoryOfProjects';
import NewCategoryOfProjects from './Pages/NewCategoryOfProjects';
import AllProducts from './Pages/AllProducts';
import NewProduct from './Pages/NewProduct';
import ServicesContextProvider from './Context/ServicesContext';
import EditCategory from './Pages/EditCategory';
import LogIn from './Components/LogIn/LogIn';
import CategoryOfProducts from './Pages/CategoryOfProducts';
import NewCategoryOfProducts from './Pages/NewCategoryOfProducts';
import NewSubCategoryOfProducts from './Pages/NewSubCategoryOfProducts';
import AllNews from './Pages/AllNews';
import NewPost from './Pages/NewPost';
import AboutContextProvider from './Context/AboutContext';
import EditAbout from './Components/About/EditAbout/EditAbout';
import EditService from './Pages/EditService';
import ProjectsContextProvider from './Context/ProjectsContext';
import EditProjectsCategory from './Pages/EditProjectsCategory';
import AuthContextProvider from './Context/AuthContext';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import EditProject from './Pages/EditProject';
import Careers from './Pages/Careers';
import CareersContextProvider, { CareersContext } from './Context/CareersContext';
import NewCareer from './Pages/NewCareer';
import EditCareer from './Pages/EditCareer';
import NewsContextProvider from './Context/NewsContext';
import EditPost from './Pages/EditPost';

function App() {
  const routers = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: "", element: <Navigate to="Banner" /> },
        { path: 'Banner', element: <ProtectedRoute><Banner /></ProtectedRoute> },

        { path: 'Services/AllServices', element: <ProtectedRoute><AllServices /></ProtectedRoute> },
        { path: 'Services/NewService', element: <ProtectedRoute><NewService /></ProtectedRoute> },
        { path: 'Services/EditService/:id', element: <ProtectedRoute><EditService /></ProtectedRoute> },

        { path: 'Services/Categories', element: <ProtectedRoute><CategoryOfServices /></ProtectedRoute> },
        { path: '/Services/NewCategory', element: <ProtectedRoute><NewCategory /></ProtectedRoute> },
        { path: 'Services/EditCategory/:id', element: <ProtectedRoute><EditCategory /></ProtectedRoute> },

        { path: 'About', element: <ProtectedRoute><About /> </ProtectedRoute> },
        { path: 'About/EditAbout/:id', element: <ProtectedRoute><EditAbout /> </ProtectedRoute> },

        { path: 'Projects/Categories', element: <ProtectedRoute><CategoryOfProjects /></ProtectedRoute> },
        { path: 'Projects/NewCategory', element: <ProtectedRoute><NewCategoryOfProjects /> </ProtectedRoute> },
        { path: 'Projects/EditCategory/:id', element: <ProtectedRoute><EditProjectsCategory /></ProtectedRoute> },

        { path: 'Projects/AllProjects', element: <ProtectedRoute><AllProject /></ProtectedRoute> },
        { path: 'Projects/NewProject', element: <ProtectedRoute><NewProject /></ProtectedRoute> },
        { path: 'Projects/EditProject/:id', element: <ProtectedRoute><EditProject /></ProtectedRoute> },

        { path: 'Careers/EditCareer/:id', element: <ProtectedRoute><EditCareer /></ProtectedRoute> },
        { path: 'Careers', element: <ProtectedRoute><Careers /></ProtectedRoute> },
        { path: 'Careers/NewCareer', element: <ProtectedRoute><NewCareer /></ProtectedRoute> },

        { path: 'News/AllNews', element: <ProtectedRoute><AllNews /></ProtectedRoute> },
        { path: 'News/NewPost', element: <ProtectedRoute><NewPost /></ProtectedRoute> },
        { path: 'News/EditPost/:id', element: <ProtectedRoute><EditPost /></ProtectedRoute> },

        { path: 'AllProducts', element: <ProtectedRoute><AllProducts /></ProtectedRoute> },
        { path: 'NewProduct', element: <ProtectedRoute><NewProduct /></ProtectedRoute> },
        { path: 'CategoryOfProducts', element: <ProtectedRoute><CategoryOfProducts /></ProtectedRoute> },
        { path: 'NewCategoryOfProducts', element: <ProtectedRoute><NewCategoryOfProducts /></ProtectedRoute> },
        { path: 'SubCategory', element: <ProtectedRoute><NewSubCategoryOfProducts /></ProtectedRoute> },

      ],
    },
    { path: 'Login', element: <LogIn /> },
  ]);

  return (
    <div className="App">
      <ServicesContextProvider>
        <ProjectsContextProvider>
          <AboutContextProvider>
            <NewsContextProvider>
            <CareersContextProvider>
              <AuthContextProvider>
                <RouterProvider router={routers}></RouterProvider>
              </AuthContextProvider>
            </CareersContextProvider>
            </NewsContextProvider>
          </AboutContextProvider>
        </ProjectsContextProvider>
      </ServicesContextProvider>
    </div>
  );
}

export default App;
