import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useAbout } from '../../../Context/AboutContext';

function EditAbout() {
    const [selectedFile, setSelectedFile] = useState(null);
    const { isAboutById, getAboutById } = useAbout()

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/About", { replace: true });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getAboutById(id);
    }, []);

    const handleSaveClick = async () => {
        try {
            const body = {
                'lang_id': isAboutById?.data[0].lang_id,
                'title': document.getElementById('title').value,
                'excerpt': document.getElementById('excerpt').value,
                'description': document.getElementById('description').value,
                'image': selectedFile,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/about/${isAboutById?.data[0].id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.status);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            {isAboutById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div
                            className="flex rounded-lg bg-[#F2F4F7]"
                        >
                            <label
                                htmlFor="fileInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                            >
                                <img
                                    src={isAboutById?.data[0].image_url != null && selectedFile == null ? isAboutById?.data[0].image_url :
                                        isAboutById?.data[0].image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isAboutById?.data[0].image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="pl-[50px]" style={{ width: '100%' }}>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Title
                        </label>

                        <input
                            type={'text'}
                            id={'title'}
                            name={'title'}
                            disabled={true}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isAboutById?.data[0].title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isAboutById?.data[0].excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Description
                        </label>

                        <textarea
                            id={'description'}
                            name={'description'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isAboutById?.data[0].description}
                            style={{
                                height: "120px",
                                paddingBottom: "3rem",
                            }}
                        />

                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditAbout