import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import remove from "../../../Assets/Images/remove.png";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useProjects } from '../../../Context/ProjectsContext';

function EditProjectForm() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const { isProjectById, getProjectById } = useProjects()
    const [nameShowError, setNameShowError] = useState(false);
    const [excerptShowError, setExcerptShowError] = useState(false);
    const [descriptionShowError, setDescriptionShowError] = useState(false);
    const [inputs, setInputs] = useState([{ text: '', image: null }]); // Initialize with one empty inp ut
    const { deleteProjectGalleryImage } = useProjects();

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Projects/AllProjects", { replace: true });

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...inputs];
        if (event.target.files) {
            newInputs[index][name] = event.target.files[0]; // Store only the first file
        } else {
            newInputs[index][name] = value;
        }
        setInputs(newInputs);
        console.log(inputs);
    };

    const handleAddInput = () => {
        setInputs([...inputs, { text: '', image: null }]);
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const handleFilesChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
        event.target.value = null;
        console.log(selectedFiles);
    };

    const handleRemoveImage = (indexToRemove, id) => {
        deleteProjectGalleryImage(id)
        const updatedFiles = selectedFiles.filter((file, index) => index !== indexToRemove);
        setSelectedFiles(updatedFiles);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        // Example fetch request
        getProjectById(id).then((data) => {
            
        }).catch((error) => {
            console.error('Error fetching project by ID:', error);
        });
    }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

    // Update selectedFiles when isProjectById changes
    useEffect(() => {
        if (isProjectById?.data?.main_project?.gallery) {
            setSelectedFiles(isProjectById.data.main_project.gallery);
        }
        if (isProjectById?.data?.main_project?.description) {
            setInputs(isProjectById.data.main_project.description);
        }
    }, [isProjectById]);

    const handleSaveClick = async () => {
        if (document.getElementById('title').value === "") {
            setNameShowError(true)
        } else if (document.getElementById('title').value != "") {
            setNameShowError(false)
        } if (document.getElementById('excerpt').value === "") {
            setExcerptShowError(true)
        } else if (document.getElementById('excerpt').value != "") {
            setExcerptShowError(false)
        } if (nameShowError == false && excerptShowError == false && descriptionShowError == false) {
            try {
                const body = {
                    'lang_id': isProjectById?.data?.main_project?.lang_id,
                    'title': document.getElementById('title').value,
                    'excerpt': document.getElementById('excerpt').value,
                    'project_owner': "hfgh",
                    'area': "jhjhj",
                    'year': "hgfhgfh",
                    'status': selectedStatus != 0 ? selectedStatus : isProjectById?.data?.main_project?.status,
                    'project_cat_id': selectedCategory != 0 ? selectedCategory : isProjectById?.data?.main_project?.project_cat_id,
                    'image': selectedFile,
                };

                if (selectedFiles) {
                    body['gallery_images'] = selectedFiles;
                }

                
                if (inputs[0].text !== '' && inputs[0].image !== null) {
                    body['descriptions'] = inputs;
                }

                const response = await axios.post(`${newBaseUrl}/api/update/project/${id}`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(response.status);
                handleGoToMenu();
                // console.log(res);
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <div>
            {isProjectById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div className="flex rounded-lg bg-[#F2F4F7]">
                            <label
                                htmlFor="fileInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]">
                                <img
                                    src={isProjectById?.data?.main_project?.image_url != null && selectedFile == null ? isProjectById?.data?.main_project?.image_url :
                                        isProjectById?.data?.main_project?.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isProjectById?.data?.main_project?.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                                    />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}>
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="scrollable-content pl-[50px]" style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >

                            Name
                        </label>

                        <input
                            type={'text'}
                            id={'title'}
                            name={'Title'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isProjectById?.data?.main_project?.title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        {nameShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Name.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Category
                        </label>

                        <select
                            name="category"
                            id="category"
                            value={selectedCategory != 0 ? selectedCategory : isProjectById?.data?.main_project?.category?.id}
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isProjectById?.data?.projectsCategories.map((Project, index) => (
                                <option key={Project.id} value={Project.id}>{Project.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isProjectById?.data?.main_project?.excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        {excerptShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Excerpt.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isProjectById?.data?.main_project?.status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>

                        <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Gallery
                    </label>

                    <div className="grid grid-cols-6 gap-y-5 mb-2">
                        <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                            <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                <img
                                    src={photo}
                                    alt=""
                                    className={"w-[75px] h-[75px]"}
                                />
                                <input
                                    type="file"
                                    className="hidden"
                                    onChange={handleFilesChange}
                                />
                            </label>
                        </div>

                        {selectedFiles.map((file, index) => (
                            <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]" key={index}>
                                <div className="relative">
                                    <img
                                        src={file == null ? photo : file?.image_url != null ? file?.image_url : URL.createObjectURL(file)}
                                        alt="File Upload"
                                        className={"rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                    />
                                    <button
                                        className="absolute top-0 left-0 bg-transparent rounded-full p-1 m-2"
                                        onClick={() => handleRemoveImage(index, file?.id)}
                                    >
                                        <img src={remove} alt="Close" className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Description
                    </label>
                    <div>
                        {inputs.map((input, index) => (
                            <div key={index} className="flex flex-row items-center justify-center gap-5">
                                <input
                                    type={'text'}
                                    name="text"
                                    defaultValue={input?.description}
                                    onChange={(event) => handleInputChange(index, event)}
                                    className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                                    placeholder={`Type excerpt here`}
                                    style={{
                                        height: "150px",
                                        paddingBottom: "7rem",
                                    }}
                                />
                                <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                                    <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                        <img
                                            src={input.image == null ? photo : input?.image_url != null ? input?.image_url : input?.image_url != null &&  input.image != null ? URL.createObjectURL(input.image) : null}
                                            alt="File Upload"
                                            className={input.image == null ? "w-[75px] h-[75px]" : "rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="image"
                                            className="hidden"
                                            onChange={(event) => handleInputChange(index, event)}
                                        />
                                    </label>
                                </div>

                                {index != 0 ? <button className="rounded-lg bg-[#AF1F24] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                    onClick={() => handleRemoveInput(index)} >-</button> : <button
                                        className="rounded-lg bg-[#148342] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                        onClick={handleAddInput}>+</button>}
                            </div>

                        ))}

                    </div>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditProjectForm