import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../../../Context/ServicesContext';

function EditServiceForm() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const { isServiceById, getServiceById } = useServices()
    const [nameShowError, setNameShowError] = useState(false);
    const [excerptShowError, setExcerptShowError] = useState(false);
    const [descriptionShowError, setDescriptionShowError] = useState(false);

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Services/AllServices", { replace: true });

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    useEffect(() => {
        getServiceById(id);
    }, []);

    const handleSaveClick = async () => {
        if (document.getElementById('name').value === "") {
            setNameShowError(true)
        } else if (document.getElementById('name').value != "") {
            setNameShowError(false)
        } if (document.getElementById('excerpt').value === "") {
            setExcerptShowError(true)
        } else if (document.getElementById('excerpt').value != "") {
            setExcerptShowError(false)
        } if (document.getElementById('description').value === "") {
            setDescriptionShowError(true)
        } else if (document.getElementById('description').value != "") {
            setDescriptionShowError(false)
        } if(nameShowError == false && excerptShowError == false && descriptionShowError == false) {
            try {
                const body = {
                    'lang_id':isServiceById?.data?.services?.lang_id,
                    'title': document.getElementById('name').value,
                    'excerpt': document.getElementById('excerpt').value,
                    'description': document.getElementById('description').value,
                    'status': selectedStatus != 0 ? selectedStatus : isServiceById?.data?.services?.status,
                    'service_cat_id': selectedCategory != 0 ? selectedCategory : isServiceById?.data?.services?.service_cat_id,
                    'image': selectedFile,
                };
                const response = await axios.post(`${newBaseUrl}/api/update/service/${id}`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(response.status);
                handleGoToMenu();
                // console.log(res);
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <div>
            {isServiceById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div className="flex flex-col gap-5 pr-[50px]">
                        <div
                            className="flex rounded-lg bg-[#F2F4F7]"
                        >
                            <label
                                htmlFor="fileInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                            >
                                <img
                                    src={isServiceById?.data?.services?.image_url != null && selectedFile == null ? isServiceById?.data?.services?.image_url :
                                        isServiceById?.data?.services?.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                    alt="File Upload"
                                    className={selectedFile == null && isServiceById?.data?.services?.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="pl-[50px]" style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Name
                        </label>

                        <input
                            type={'text'}
                            id={'name'}
                            name={'Name'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type name here`}
                            defaultValue={isServiceById?.data?.services?.title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />
                        
                        {nameShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Name.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Category
                        </label>

                        <select
                            name="category"
                            id="category"
                            value={selectedCategory != 0 ? selectedCategory : isServiceById?.data?.services?.category?.id}
                            onChange={(e) => {
                                setSelectedCategory(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isServiceById?.data?.servicesCategories.map((service, index) => (
                                <option key={service.id} value={service.id}>{service.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isServiceById?.data?.services?.excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        {excerptShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Excerpt.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Description
                        </label>

                        <textarea
                            id={'description'}
                            name={'Description'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type description here`}
                            defaultValue={isServiceById?.data?.services?.description}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        {descriptionShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Description.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isServiceById?.data?.services?.status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>

                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditServiceForm