import React from 'react'
import profile from "../../Assets/Images/photof.jpg"
import { useAuth } from '../../Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { newBaseUrl } from '../../Consts'

function Header() {
  const { isAuth, setIsAuth } = useAuth()
  const navigate = useNavigate()

  async function logOut() {
    setIsAuth(false)
    localStorage.removeItem('token')
    navigate("/Login")
    // try {
    //   const res = await axios.post(`${newBaseUrl}/api/logout`, null, {
    //     headers: {
    //       Authorization: sessionStorage.getItem('token'),
    //     }
    //   });
    //   setIsAuth(false)
    //   sessionStorage.removeItem('token')
    //   navigate("/Login")
    //   console.log(res.data);
    // } catch (err) {
    //   console.error(err);
    // }
  }

  return (
    <div className='flex  items-center justify-between'>
      <div>
        <h1 className='text-[28px] font-[600] text-[#0966AE] font-[Cairo] '>Services Category - Edit</h1>
      </div>
      <div>
        <a onClick={logOut}>
          <img src={profile} alt="" className='w-[60.462px] rounded-full' />
        </a>
      </div>
    </div>
  )
}

export default Header