import React from 'react'
import { Link } from 'react-router-dom'

function Btn() {
    return (
        <Link to={"/NewProduct"}>
            <div className='text-[20px] font-[200] text-[#fff] px-5 rounded-3xl py-2 bg-[#0966AE] font-[Cairo]'>New Products</div>
        </Link>
    )
}

export default Btn