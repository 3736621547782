import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const AboutContext = createContext();
export const useAbout = () => {
    return useContext(AboutContext);
};

export default function AboutContextProvider({ children }) {
    const [isAbout, setIsAbout] = useState()
    const [isAboutById, setIsAboutById] = useState()

    async function getAbout(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/about`, { headers });
            setIsAbout(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getAboutById(id) {
        setIsAboutById()
        try {
            const res = await axios.get(`${newBaseUrl}/api/about/${id}`);
            setIsAboutById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <AboutContext.Provider value={{
            getAbout, getAboutById, isAbout, isAboutById
        }}>
            {children}
        </AboutContext.Provider>
    );
}
