import React, { useEffect, useState } from 'react'
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useCareers } from '../../../Context/CareersContext';

function EditCareerForm() {
     const [selectedStatus, setSelectedStatus] = useState(null);
    const { isCareerById, getCareerById } = useCareers()
    const [nameShowError, setNameShowError] = useState(false);
    const [excerptShowError, setExcerptShowError] = useState(false);

    const { id } = useParams();

    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Careers", { replace: true });

    useEffect(() => {
        getCareerById(id).then((data) => {

        }).catch((error) => {
            console.error('Error fetching Career by ID:', error);
        });
    }, []);

    useEffect(() => {
        if (isCareerById?.data?.status) {
            setSelectedStatus(isCareerById?.data?.status);
        }

    }, [isCareerById]);

    const handleSaveClick = async () => {
        if (document.getElementById('title').value === "") {
            setNameShowError(true)
        } else if (document.getElementById('title').value != "") {
            setNameShowError(false)
        } if (document.getElementById('excerpt').value === "") {
            setExcerptShowError(true)
        } else if (document.getElementById('excerpt').value != "") {
            setExcerptShowError(false)
        } if (nameShowError == false && excerptShowError == false) {
            try {
                const body = {
                    'lang_id': isCareerById?.data?.lang_id,
                    'title': document.getElementById('title').value,
                    'excerpt': document.getElementById('excerpt').value,
                    'details': document.getElementById('details').value,
                    'location': document.getElementById('location').value,
                    'position': document.getElementById('position').value,
                    'emp_status': document.getElementById('emp_status').value,
                    'skills': document.getElementById('skills').value,
                    'education': document.getElementById('education').value,
                    'status': selectedStatus,
                };

                const response = await axios.post(`${newBaseUrl}/api/update/career/${id}`, body, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log(response.status);
                handleGoToMenu();
                // console.log(res);
            } catch (err) {
                console.error(err);
            }
        }
    };

    return (
        <div>
            {isCareerById ? (
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                    <div style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >

                            Title
                        </label>

                        <input
                            type={'text'}
                            id={'title'}
                            name={'Title'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isCareerById?.data?.title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        {nameShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Name.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Job Location
                        </label>

                        <input
                            type={'text'}
                            id={'location'}
                            name={'Location'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Job Location here`}
                            defaultValue={isCareerById?.data?.location}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Job Position
                        </label>

                        <input
                            type={'text'}
                            id={'position'}
                            name={'Position'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Job Position here`}
                            defaultValue={isCareerById?.data?.position}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Employment Status
                        </label>

                        <input
                            type={'text'}
                            id={'emp_status'}
                            name={'EmpStatus'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Employment Status here`}
                            defaultValue={isCareerById?.data?.emp_status}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isCareerById?.data?.excerpt}
                            style={{
                                height: "80px",
                                paddingBottom: "3rem",
                            }}
                        />

                        {excerptShowError && <p style={{ color: 'red', textAlign: "start", fontSize: "14px" }}>Please enter Excerpt.</p>}

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Job Details
                        </label>

                        <input
                            id={'details'}
                            name={'Details'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Job Details here`}
                            defaultValue={isCareerById?.data?.details}
                            style={{
                                height: "150px",
                                paddingBottom: "7rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Skills
                        </label>

                        <input
                            id={'skills'}
                            name={'Skills'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Skills here`}
                            defaultValue={isCareerById?.data?.skills}
                            style={{
                                height: "150px",
                                paddingBottom: "7rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Education
                        </label>

                        <input
                            id={'education'}
                            name={'Education'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Education here`}
                            defaultValue={isCareerById?.data?.education}
                            style={{
                                height: "150px",
                                paddingBottom: "7rem",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>
                        <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}>
                            Save
                        </button>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}

        </div>
    )
}

export default EditCareerForm