import React from "react";
import Pagination from "../../Pagination/Pagination";
import banner from "../../../Assets/Images/banner.jpeg";
import delet from "../../../Assets/Images/delete.svg";
import edit from "../../../Assets/Images/edit.svg";
import Popup from "reactjs-popup";
import DeletModal from "../../DeletModal/DeletModal";
function CategoryTable() {
  return (
    <div className="bg-[#fff] rounded-2xl p-3">
      <table className="w-full border-collapse mb-6">
        <thead>
          <tr className="p-2">
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              SL No
            </th>
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              Image
            </th>
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              Title
            </th>
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              Sorting Order
            </th>
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              Category
            </th>
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              Status
            </th>
            <th className="text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-5 text-start">01.</td>
            <td className="p-5 text-start">
              <img src={banner} alt="" className="w-[150px] rounded-xl" />
            </td>
            <td className="p-5 text-start">Welcome To Arch Sense</td>
            <td className="p-5 text-start">1</td>
            <td className="p-5 text-start">Decore</td>
            <td className="p-5 text-start">Publish</td>
            <td className="p-5 text-center">
              <div className="flex gap-2  items-center">
                <img
                  src={edit}
                  alt=""
                  className="bg-[#148342] p-1 w-[2vw] cursor-pointer"
                />
                <Popup
                  trigger={
                    <img
                      src={delet}
                      alt=""
                      className="p-1 bg-[#AF1F24] w-[2vw] cursor-pointer"
                    />
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <DeletModal close={close} />
                    </div>
                  )}
                </Popup>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination />
    </div>
  );
}

export default CategoryTable;
