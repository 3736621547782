import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";

function SideBar() {
  const location = useLocation();

  const [menuItems, setMenuItems] = useState([
    { name: "Dashboard", isOpen: false, path: "Banner" },
    { name: "Home", isOpen: false, path: "" },
    { name: "About", isOpen: false, path: "About" },
    { name: "Services", isOpen: false, path: "Services", subMenu: [{ name: "All Services", path: "AllServices" }, { name: "Categories", path: "Categories" }] },
    { name: "Projects", isOpen: false, path: "Projects", subMenu: [{ name: "All Projects", path: "AllProjects" }, { name: "Categories", path: "Categories" }] },
    { name: "Products", isOpen: false },
    { name: "Careers", isOpen: false, path: "Careers"},
    { name: "News", isOpen: false, path: "News", subMenu: [{ name: "All News", path: "AllNews" }, { name: "Categories", path: "Categories" }] },
    { name: "Parents", isOpen: false },
    { name: "Setting", isOpen: false },
  ]);

  useEffect(() => {
    const pathname = location.pathname;
    const updatedMenuItems = menuItems.map(item => {
      if (item.subMenu) {
        const isOpen = item.subMenu.some(subItem => `/${item.path}/${subItem.path}` === pathname || pathname.includes(`/${item.path}`));
        return { ...item, isOpen };
      }
      return item;
    });
    setMenuItems(updatedMenuItems);
  }, [location.pathname]); // Update when the pathname changes

  const toggleMenuItem = (index) => {
    setMenuItems((prevItems) =>
      prevItems.map((item, i) => {
        if (i === index) {
          return { ...item, isOpen: !item.isOpen };
        } else if (item.subMenu) {
          // Check if the current item is a parent of the active submenu
          const isActiveSubMenuParent = item.subMenu.some(subItem => `/${item.path}/${subItem.path}` === location.pathname || location.pathname.includes(`/${item.path}`));
          // Keep the current menu open if it's a parent of the active submenu
          const isOpen = isActiveSubMenuParent;
          return { ...item, isOpen };
        }
        return { ...item, isOpen: false }; // Close all other menus
      })
    );
  };

  return (
    <nav className="h-[100vh]  w-[310px] bg-[#fff] border border-s ">
      <img className="m-auto w-[210px] h-[110px]" src={logo} alt="" />
      <ul className="relative m-0 list-none ps-[1.2rem]" data-te-sidenav-menu-ref>
        {menuItems.map((item, index) => (
          <li key={index} className="nav relative">
            <div
              className={`flex gap-1 h-12 cursor-pointer items-center truncate rounded-bl-[10px] rounded-tl-[10px] ps-3 py-4 text-[18px] font-[500] font-[Cairo] ${item.isOpen && item.subMenu ? "text-[#0966AE] active-item" : "text-[#B1B1B1]"
                }`}
              onClick={() => toggleMenuItem(index)}
            >
              {item.subMenu ?
                <span>{item.name}</span>
                :
                <span>
                  <Link
                    to={`/${item.path}`}
                    className={`${location.pathname === `/${item.path}` ? "text-[#0966AE]" : "text-[#B1B1B1]"
                      }`}
                  >
                    {item.name}
                  </Link>
                </span>
              }
              {item.subMenu && item.subMenu.length > 0 && (
                <span
                  className={`absolute right-8 ml-auto transition-transform duration-300 ease-linear motion-reduce:transition-none ${item.isOpen ? "rotate-[180deg] text-[#0966AE]" : "text-[#B1B1B1]"
                    }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 transform">
                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                  </svg>
                </span>
              )}
            </div>
            {item.isOpen && item.subMenu && (
              <ul className="relative m-0 list-none ps-[1.2rem]">
                {item.subMenu.map((subItem, subIndex) => (
                  <li key={subIndex} className="nav relative">
                    <Link
                      to={`/${item.path}/${subItem.path}`}
                      className={`flex gap-1 h-12 cursor-pointer items-center truncate rounded-bl-[10px] rounded-tl-[10px] ps-3 py-4 text-[18px] font-[500] font-[Cairo] ${location.pathname === `/${item.path}/${subItem.path}` ? "text-[#0966AE]" : "text-[#B1B1B1]"
                        }`}
                    >
                      <span>{subItem.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}

      </ul>
    </nav>
  );
}

export default SideBar;
