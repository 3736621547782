import React, { useState } from "react";
import photo from "../../../Assets/Images/Vector.svg";

function NewSubCategoryForm() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const inputFields = [
    {
      label: "Language",
      type: "select",
      id: "Language",
      options: ["Select Lang", "English", "Arabic"],
    },
    { label: "Name", type: "text", id: "Name" },
    {
      label: "Category",
      type: "select",
      id: "Category",
      options: ["Select Category", "Category", "Category"],
    },
    {
      label: "Status",
      type: "select",
      id: "Status",
      options: ["Select Status", "English", "Arabic"],
    },
  ];
  return (
    <div>
      <div className="flex justify-around bg-[#fff] rounded-2xl p-12">
        <div className="flex flex-col gap-5">
          <div className="flex justify-around rounded-lg bg-[#F2F4F7]">
            <label
              htmlFor="fileInput"
              className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
            >
              <img
                src={
                  selectedFile == null
                    ? photo
                    : URL.createObjectURL(selectedFile)
                }
                alt="File Upload"
                className={
                  selectedFile == null
                    ? "w-[100px] h-[100px]"
                    : "rounded-lg w-[200px] h-[200px]"
                }
              />
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
          </div>
          <div>
            <button className="bg-[#0966AE] text-[#fff] rounded-lg px-14 py-2  m-0 w-full text-[24px] font-[500] font-[Cairo]">
              Save
            </button>
          </div>
        </div>
        <div className="scrollable-content">
          {inputFields.map((field) => (
            <div key={field.id} className=" pb-2">
              <label
                htmlFor={field.id}
                className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
              >
                {field.label}
              </label>
              {field.type === "select" ? (
                <select
                  id={field.id}
                  name={field.label.replace(" ", "")}
                  className="border rounded-lg text-[13px] font-[400] font-[Cairo] p-2 w-[670px] h-[40px] border-[#DFEAF2] text-[#718EBF]"
                >
                  {field.options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  id={field.id}
                  name={field.label.replace(" ", "")}
                  className="border rounded-lg text-[13px] font-[400] font-[Cairo] p-2 w-[670px] h-[40px] border-[#DFEAF2] custom-placeholder-style"
                  placeholder={`Type ${field.label.toLowerCase()} here`}
                  style={{
                    height: field.id === "Excerpt" ? "80px" : "",
                    paddingBottom: field.id === "Excerpt" ? "3rem" : "8px",
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewSubCategoryForm;
