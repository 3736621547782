import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"
import NewCategoryForm from "../Components/Services/NewCategory/NewCategoryForm";

export const ServicesContext = createContext();
export const useServices = () => {
    return useContext(ServicesContext);
};

export default function ServicesContextProvider({ children }) {

    const [isServices, setIsServices] = useState()
    const [isServiceById, setIsServiceById] = useState()
    const [isServicesCategory, setIsServicesCategory] = useState()
    const [isServicesCategoryById, setIsServicesCategoryById] = useState()

    async function getServices(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/services`, { headers });
            setIsServices(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getServiceById(id) {
        setIsServiceById()
        try {
            const res = await axios.get(`${newBaseUrl}/api/admin/service/${id}`);
            setIsServiceById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteService(id) {
        try {
            const res = await axios.delete(`${newBaseUrl}/api/delete/service/${id}`);
            // setIsServices(res?.data);
            // console.log(res?.data?.data);
            getServices()
        } catch (err) {
            console.error(err);
        }
    }

    async function getServicesCategory(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/services-category`, { headers });
            setIsServicesCategory(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getServicesCategoryById(id) {
        setIsServicesCategoryById()
        try {
            const res = await axios.get(`${newBaseUrl}/api/admin/services-category/${id}`);
            setIsServicesCategoryById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteServicesCategory(id) {
        try {
            const res = await axios.delete(`${newBaseUrl}/api/delete/services-category/${id}`);
            // setIsServices(res?.data);
            // console.log(res?.data?.data);
            getServicesCategory()
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <ServicesContext.Provider value={{
            getServicesCategory, getServices, deleteServicesCategory, getServicesCategoryById, getServiceById, deleteService,
            isServicesCategoryById, isServicesCategory, isServices, isServiceById
        }}>
            {children}
        </ServicesContext.Provider>
    );
}
