import React, { useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate } from 'react-router-dom';

function NewCategoryForm() {
    const [selectedLanguage, setSelectedLanguage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const navigate = useNavigate();
    const handleGoToMenu = () => navigate("/Projects/Categories", { replace: true });

    const handleSaveClick = async () => {
        try {
            const body = {
                'lang_id': selectedLanguage,
                'name': document.getElementById('name').value,
                'excerpt': document.getElementById('excerpt').value,
                'description': "description",
                'status': selectedStatus,
            };
            const response = await axios.post(`${newBaseUrl}/api/store/projects-category`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.status);
            handleGoToMenu();
            // console.log(res);
        } catch (err) {
            console.error(err);
        }
    };

    return (
            <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
                <div style={{ width: '100%' }}>
                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Language
                    </label>
                    <select
                        name="Language"
                        id="language"
                        value={selectedLanguage}
                        onChange={(e) => {
                            setSelectedLanguage(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value="1">English</option>
                        <option value="2">Arabic</option>
                    </select>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Name
                    </label>

                    <input
                        type={'text'}
                        id={'name'}
                        name={'Name'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type name here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Excerpt
                    </label>

                    <input
                        type={'text'}
                        id={'excerpt'}
                        name={'Excerpt'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type excerpt here`}
                        style={{
                            height: "80px",
                            paddingBottom: "3rem",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Status
                    </label>

                    <select
                        name="Status"
                        id="status"
                        value={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value="1">Publish</option>
                        <option value="2">Draft</option>
                    </select>
                    <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 mt-[25px] w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                </div>
        </div>
    )
}

export default NewCategoryForm